
.chart-main >>> p {
  line-height: 28px;
  text-indent: 2em;
}
.chart-main >>> img {
  max-width: 800px;
  margin: 0 auto;
  height: auto;
}
.chart-main >>> p .role3 {
  /* 4补字 3行内 */
  display: inline-block;
  max-height: 18px;
}
.chart-main >>> p .role4 {
  display: inline-block;
  width: 13px !important;
  height: 13px !important;
}

.chart-detail-page {
    // background: blue;

    .chart-content {
        display: flex;
        justify-content: space-between;
        .left-chart-wrap {
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            padding: 0 22px;
            margin-top: 24px;
            margin-bottom: 34px;
            img.table-img {
                width: 807px;
                // height: 150px;
                margin: 22px auto 22px;
                border: 1px solid #e6e6e6;
            }
            .chart-info-wrap {
                position: relative;
                z-index: 0;
                .chart-bg {
                    position: absolute;
                    left: 9px;
                    top: 9px;
                    z-index: -1;
                    width: 798px;
                    height: 165px;
                    background: #e5e5e5;
                    opacity: 0.92;
                    border-radius: 2px;
                }
                .chart-info {
                    width: 798px;
                    height: 165px;
                    background: #dbbc85;
                    font-size: 16px;
                    padding-left: 20px;
                    box-sizing: border-box;
                    padding-top: 14px;
                    margin-bottom: 42px;
                    p {
                        color: #fff;
                        line-height: 35px;
                        span:first-child {
                            font-weight: bold;
                        }
                        a {
                            color: #fff;
                        }
                    }
                }
            }
            .chart-main-wrap {
                text-align: center;
                .chart-title {
                    text-align: center;

                    font-size: 22px;
                    color: #333;
                }
                .chart-main {
                    width: 816px;
                    text-align: left;
                    margin: 30px auto 24px;
                    font-size: 14px;
                    color: #666;
                    line-height: 24px;
                }
                .chart-btn {
                    width: 120px;
                    height: 40px;

                    line-height: 40px;
                    box-sizing: border-box;
                    border: 1px solid #bd1a2d;
                    font-size: 18px;
                    color: #bd1a2d;
                    font-weight: 400;
                    margin: 0 auto 21px;
                    cursor: pointer;
                    a {
                        color: #bd1a2d;
                    }
                }
            }
        }

        .right-download-wrap {
            width: 316px;
            padding: 0 16px;
            margin-top: 24px;
            box-sizing: border-box;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            .com-title {
                height: 45px;
                line-height: 45px;
                border-bottom: 2px solid #dedede;
                display: flex;
                align-items: center;
                justify-content: space-between;
                div {
                    display: flex;
                    align-items: center;

                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 11px;
                        margin-left: 3px;
                    }
                    h5 {
                        height: 45px;
                        line-height: 45px;
                        font-size: 24px;
                        color: #000000;
                        border-bottom: 3px solid #bd1a2d;
                    }
                }

                ul {
                    display: flex;
                    li {
                        font-size: 18px;
                        margin-right: 18px;
                        cursor: pointer;
                        line-height: 36px;
                        color: #333;
                    }
                }
                span {
                    font-size: 14px;
                    color: #404040;
                    cursor: pointer;
                    img {
                        width: 4px;
                        height: 8px;
                        margin-left: 10px;
                    }
                }
            }
            ul.right-download {
                .first-download {
                    display: flex;
                    margin-top: 14px;
                    div {
                        img {
                            width: 118px;
                            height: 74px;
                            margin-right: 9px;
                            margin-bottom: 15px;
                        }
                        h5 {
                            width: 155px;
                            font-size: 16px;
                            color: #333333;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-bottom: 10px;
                        }
                        p {
                            width: 155px;
                            font-size: 14px;
                            color: #666666;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                .download-li {
                    width: 282px;
                    box-sizing: border-box;
                    line-height: 17px;
                    margin: 0 auto 18px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-left: 10px;
                    display: inline-block;
                    position: relative;
                    img {
                        width: 19px;
                        height: 19px;
                        margin-right: 4px;
                        vertical-align: middle;
                    }
                }
                .download-li::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #ffac13;
                    position: absolute;
                    left: 0;
                    top: 8px;
                }
            }
        }
    }
}
